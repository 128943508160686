import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import moment from 'moment'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'utente', sortable: true },
    { key: 'reparto', sortable: true },
    { key: 'giorni', sortable: false },
    { key: 'saldo', sortable: false },
    { key: 'calendario', sortable: false },
  ]
  const fields = {
    id: 'anagrafico_id',
    utente: 'display_name',
    reparto: 'department_id',
    giorni: 'days',
    saldo: 'balance',
    calendario: 'calendar',
  }
  const perPage = ref(5)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const userFilter = ref(null)
  const departmentFilter = ref(null)
  const subDepartmentFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, departmentFilter, subDepartmentFilter, userFilter], () => {
    refetchData()
  })

  const userOptions = ref(null)

  const setUserOptions = async () => {
    userFilter.value = null
    const _users = []
    try {
      const response = await store.dispatch('app-user/fetchUsers', {
        per_page: 1000,
        for_approvation: 1,
        department_id: subDepartmentFilter.value || departmentFilter.value,
      })
      response.data[0].forEach((user, i) => {
        const _user = {
          label: `#${user.anagrafico_id} - ${user.display_name}`,
          value: user.id,
        }
        _users.push(_user)
      })
      userOptions.value = _users
    } catch (error) {
      if (error === 401) {
        // Gestisci l'errore 401 qui
      }
    }
  }

  setUserOptions()

  watch([departmentFilter, subDepartmentFilter], () => {
    setUserOptions()
  })

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('app-user/fetchUsers', {
        text_search: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value - 1,
        sort_by: fields[sortBy.value],
        sort_direction: isSortDirDesc.value === true ? 'DESC' : 'ASC',
        with_holidays: 1,
        for_approvation: 1,
        department_id: subDepartmentFilter.value || departmentFilter.value,
        user_id: userFilter.value,
      })
      .then(response => {
        const users = response.data[0]
        const total = response.data[1]
        totalUsers.value = total

        callback(users)
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
          // router.replace({ name: 'auth-login' }).then(() => {
          //   toast({
          //     component: ToastificationContent,
          //     position: 'top-right',
          //     props: {
          //       title: 'Sessione scaduta',
          //       icon: 'AlertTriangleIcon',
          //       text: "Sessione scaduta, effettuare di nuovo l'accesso per utilizzare tutte le funzionalità",
          //       variant: 'danger',
          //     },
          //   })
          // })
        }
      })
  }

  const departmentOptions = ref(null)
  const _departments = []
  store
    .dispatch('app-departments/fetchDepartments', { root: true, per_page: 1000, for_approvation: 1 })
    .then(response => {
      response.data[0].forEach((department, i) => {
        const _department = {
          label: `#${department.id} - ${department.name}`,
          value: department.id,
        }
        _departments.push(_department)
      })
    })
    .catch(error => {
      if (error === 401) {
        localStorage.removeItem('userData')
        store.commit('user/updateUserData', null)
        router.replace({ name: 'auth-login' }).then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Sessione scaduta',
              icon: 'AlertTriangleIcon',
              text: "Sessione scaduta, effettuare di nuovo l'accesso per utilizzare tutte le funzionalità",
              variant: 'danger',
            },
          })
        })
      }
    })
  departmentOptions.value = _departments

  const subDepartmentOptions = ref([])
  watch(departmentFilter, () => {
    if (!departmentFilter.value) {
      subDepartmentFilter.value = null
      subDepartmentOptions.value = []
      return
    }
    store
      .dispatch('app-departments/fetchDepartments', {
        parent_department_id: departmentFilter.value,
        per_page: 1000,
        for_approvation: 1,
      })
      .then(response => {
        subDepartmentOptions.value = response.data[0].map(department => ({
          label: `#${department.id} - ${department.name}`,
          value: department.id,
        }))
      })
      .catch(_ => {})
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleName = role => {
    if (role === 'user') return 'Utente'
    if (role === 'hr') return 'HR'
    if (role === 'reception_hub') return 'Reception HUB'
    if (role === 'reception_colle') return 'Reception COLLE'
    if (role === 'accountant') return 'Contabilità'
    if (role === 'admin') return 'Admin'
    return ''
  }

  const resolveUserRoleVariant = role => {
    if (role === 'user') return 'info'
    if (role === 'hr') return 'warning'
    if (role === 'reception_hub') return 'warning'
    if (role === 'reception_colle') return 'warning'
    if (role === 'accountant') return 'warning'
    if (role === 'admin') return 'danger'
    return 'danger'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'user') return 'UserIcon'
    if (role === 'hr') return 'UsersIcon'
    if (role === 'reception_hub') return 'UsersIcon'
    if (role === 'reception_colle') return 'UsersIcon'
    if (role === 'accountant') return 'UsersIcon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserXIcon'
  }

  const resolveDepartmentUserRoleName = role => {
    if (role === 'ufficio_hr') return 'Ufficio HR'
    if (role === 'responsabile') return 'Responsabile'
    if (role === 'capo_reparto') return 'Capo Reparto'
    return 'Utente'
  }

  const parseGender = gender => {
    if (gender === 'F') return 'Femmina'
    if (gender === 'M') return 'Maschio'
    return gender
  }

  const parseDate = date => {
    const localeDate = moment(date).format('DD/MM/YYYY')
    return localeDate
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleName,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveDepartmentUserRoleName,
    parseGender,
    parseDate,
    refetchData,

    departmentOptions,
    departmentFilter,
    subDepartmentOptions,
    subDepartmentFilter,
    userOptions,
    userFilter,
  }
}
